<template>
  <section>
    <!--TRIP RESUME-->
    <b-overlay :show="!tripData">
      <trip-view :trip-data="tripData" />
    </b-overlay>
    <!--ACTION-->
    <section class="d-flex justify-content-end my-50">
      <b-button :to="{ name: 'trip-view', params: { trip_id: this.$route.params.trip_id } }">
        <font-awesome-icon icon="stream" class="mr-50" />
        {{ $t('trip.return_to_timeline') }}
      </b-button>
    </section>

    <form-wizard
      id="wizardCheckout"
      ref="refFormWizard"
      color="#DCC181"
      :title="null"
      :subtitle="null"
      finish-button-text="Submit"
      back-button-text="Previous"
      hide-buttons
      class="checkout-form-wizard steps-transparent"
    >
      <!-- account detail tab -->
      <tab-content ref="checkoutService" :title="$t('trip.shop.request_summary')" :before-change="validationShop">
        <b-overlay :show="cartLoaded" spinner-medium>
          <b-alert v-if="!cart || (cart && !cart.cartLines.length)" variant="primary" class="m-0 d-flex align-items-center justify-content-center" show>
            <div class="alert-body">
              <span>{{ $t('trip.shop.empty_cart') }}</span>
              <strong>
                <b-link :to="{ name: 'trip-view', params: { trip_id: this.$route.params.trip_id } }">
                  {{ $t('trip.shop.action.return_to_trip') }}
                </b-link>
              </strong>
            </div>
          </b-alert>
          <cart-lines v-else :cart.sync="cart" :organizations-meta="organizationsMeta" @refetch-data="refetchData" @next-step="formWizardNextStep" />
        </b-overlay>
      </tab-content>

      <tab-content class="h-100" :title="$t('trip.shop.checkout.success')">
        <section class="h-100 flex-column d-flex align-items-center justify-content-center">
          <font-awesome-icon class="text-success mb-3" icon="thumbs-up" size="6x" />
          <h3>{{ $t('trip.shop.checkout.message_success') }}</h3>
          <b-button :to="{ name: 'trip-view', params: { trip_id: this.$route.params.trip_id } }" variant="flat-primary">
            {{ $t('trip.shop.action.return_to_trip') }}
          </b-button>
        </section>
      </tab-content>
    </form-wizard>
  </section>
</template>

<script>
import { isEqual, uniqWith } from 'lodash'

import TripView from '@/views/trip/trip-view/TripView.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import Cart from '@/models/Cart'
import CartLines from '@/views/cart/CartLines.vue'
import { patchCartValidateRequest } from '@/request/globalApi/requests/tripStepEventRequests'

export default {
  name: 'Cart',
  components: {
    CartLines,
    TripView,
    // 3rd Party
    FormWizard,
    TabContent,
  },
  data() {
    return {
      cart: new Cart(),
      organizationsMeta: [],
      cartLoaded: null,
      cartValidated: false,
      products: [],
      tripData: {},
    }
  },
  computed: {
    tripStep() {
      const stepId = Number(this.$route.params.step_id)
      return this.tripData.tripSteps.find(item => item.id === stepId)
    },
  },
  mounted() {
    this.initCart()
    this.APIFetchTrip()
  },
  methods: {
    async initCart() {
      this.cartLoaded = true
      const tripStepId = this.$route.params.event_id
      await this.cart.fetch(tripStepId)

      this.cartLoaded = false

      this.organizationsMeta = uniqWith(
        this.cart.cartLines.map(line => line.organization),
        isEqual
      )
    },
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      try {
        this.tripData = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })
      } catch (error) {
        console.warn(error)
      }
    },
    refetchData() {
      this.initCart()
    },
    validationShop() {
      return new Promise((resolve, reject) => {
        this.$refs.checkoutService.$children[0].$children[0].$refs.checkoutServiceRules.validate().then(success => {
          if (success) {
            const tripStepId = this.$route.params.event_id
            patchCartValidateRequest(tripStepId).then(() => resolve(true))
          } else {
            reject()
          }
        })
      })
    },
    formWizardNextStep() {
      this.$refs.refFormWizard.nextTab()
    },
    uniqWith,
    isEqual,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '../../../node_modules/vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-wizard.scss';

.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}

.tabs_service-add .nav-tabs {
  align-items: flex-end;
}

.vue-form-wizard .wizard-navigation .wizard-nav li {
  padding: 8px;
}

.vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgb(220 193 129);
}

.finish-button {
  background-color: #43a047 !important;
  border-color: #43a047 !important;
}
</style>
<style>
#wizardCheckout .wizard-nav.wizard-nav-pills {
  display: none;
}

#wizardCheckout .checkout-form-wizard.steps-transparent {
  background: none;
  box-shadow: none;
}
</style>
