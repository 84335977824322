/* eslint-disable class-methods-use-this */
// Validation rules
import {
  fetchCartRequest,
  patchCartRequest,
  patchCartDeliveryRequest,
  patchCartMandatoyPackageRequest,
} from '@/request/globalApi/requests/tripStepEventRequests'
import BaseModel from './_Base'

/**
 * Cart model
 * @link https://vuemc.io/#basic-usage
 * @exanple use in /src/views/
 */
class Cart extends BaseModel {
  // Default attributes that define the "empty" state.
  defaults() {
    return {
      id: null,
      cartLines: [
        {
          id: null,
          quantity: null,
          comment: null,
          deliveryDate: null,
          variant: {
            id: null,
            files: [],
            productName: {
              en: '',
            },
          },
          variantPrice: {
            id: null,
            fromQuantity: null,
            toQuantity: null,
            sellingPriceExcludingTaxes: null,
            sellingPriceIncludingTaxes: null,
            ecologicalTax: null,
            variant: {
              id: null,
              files: [],
              productName: {
                en: '',
              },
            },
            startAt: '',
            endAt: '',
          },
        },
      ],
    }
  }

  // Attribute mutations to normalize data.
  mutations() {
    return {
      id: id => id || null,
    }
  }

  //--------------------------------
  //        API REQUESTS
  //--------------------------------

  /**
     * Get a product -> GET /cart/:id
   */
  async fetch(tripStepId) {
    const response = await fetchCartRequest(tripStepId)
    const cartLines = response.data.cart.cartLines.map(cartLine => {
      const copyCartLine = cartLine
      if (cartLine.variant.packing?.type === 'minutes') {
        if (cartLine.quantity >= 1440) {
          copyCartLine.quantityUnit = 'day'
        } else if (cartLine.quantity < 60) {
          copyCartLine.quantityUnit = 'min'
        } else {
          copyCartLine.quantityUnit = 'hour'
        }
      }
      return {
        ...copyCartLine,
      }
    })
    this.set({ ...response.data.cart, cartLines })
    this.sync()
  }

  patch(tripStepId, variantId, quantity) {
    const payload = {
      variantId,
      quantity,
    }
    return patchCartRequest(tripStepId, payload)
  }

  patchMandatoryPackage(tripStepId, variantId, quantity) {
    return patchCartMandatoyPackageRequest(tripStepId, { variantId, quantity })
  }

  patchDelivery(tripStepId, product) {
    const payload = {
      variantId: product.variant.id,
      deliveryDate: product.deliveryDate,
      cartLineClass: product.cartLineClass,
    }
    return patchCartDeliveryRequest(tripStepId, payload)
  }
}

export default Cart
