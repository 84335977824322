<template>
  <section>
    <div class="col-12 px-0">
      <font-awesome-icon
        icon="bookmark"
        style="width: 20px"
      />
      <span v-if="trip" class="mx-50 font-weight-bolder">{{ trip.name }}</span>
    </div>
    <hr>
    <b-card-text class="d-flex flex-column">
      <div class="col-12 px-0 py-50">
        <font-awesome-icon
          icon="plane-departure"
          style="width: 21px"
        />
        <span v-if="steps" class="mx-50">
          <span class="text-muted">
            {{ $t('trip.shop.departure_from') }}
          </span>
          <span
            v-if="steps.flightDeparture.airport"
            class="font-weight-bolder"
          >
            {{ steps.flightDeparture.airport.name }}
          </span>

          <span
            v-else
            class="font-weight-bolder"
          >
            {{ steps.flightDeparture.address.addressName }}
          </span>

          <span
            v-if=" steps.flightDeparture.airport && steps.flightDeparture.airport.icaoCode"
            class="font-weight-bolder"
          > - {{ steps.flightDeparture.airport.icaoCode }} </span>

        </span>
      </div>
      <div class="col-12 px-0 py-50">
        <div class="py-25">
          <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
          <b-badge v-if="leg" variant="light-secondary">{{ steps.flightDeparture.eventDateTime | dateFormat }} </b-badge>
        </div>
        <div>
          <div class="py-25">
            <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
            <b-badge v-if="leg" variant="light-secondary">
              {{ $moment.utc(steps.flightDeparture.eventDateTime).format('HH:mm') }}
            </b-badge>
          </div>
          <div class="py-25">
            <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
            <b-badge v-if="steps" variant="light-secondary">{{ $moment.utc(steps.flightDeparture.eventDateTime).utcOffset(steps.flightDeparture.airport.timeZone).format('ddd, MMM Do YYYY HH:mm') }} </b-badge>
          </div>
        </div>
      </div>
    </b-card-text>
    <hr>
    <b-card-text class="d-flex flex-column">
      <div v-if="steps" class="col-12 px-0 py-50">
        <font-awesome-icon
          icon="plane-arrival"
          style="width: 21px"
        />
        <span class="text-muted ml-50">
          {{ $t('trip.shop.arrival_to') }}
        </span>
        <span
          v-if="steps.flightArrival.airport"
          class="font-weight-bolder"
        >
          {{ steps.flightArrival.airport.name }}
        </span>

        <span
          v-else
          class="font-weight-bolder"
        >
          {{ steps.flightArrival.address.addressName }}
        </span>

        <span
          v-if="steps.flightArrival.airport && steps.flightArrival.airport.icaoCode"
          class="font-weight-bolder"
        > - {{ steps.flightArrival.airport.icaoCode }} </span>
      </div>
      <div class="col-12 px-0 py-50">
        <div class="py-25">
          <span class="font-small-3 mr-50"> {{ $t('common.date') | capitalize }} :</span>
          <b-badge v-if="steps" variant="light-secondary">{{ steps.flightArrival.eventDateTime | dateFormat }} </b-badge>
        </div>
        <div>
          <div class="py-25">
            <span class="font-small-3 mr-50"> {{ $t('common.utc') }} :</span>
            <b-badge v-if="steps" variant="light-secondary">
              {{ $moment.utc(steps.flightArrival.eventDateTime).format('HH:mm') }}
            </b-badge>
          </div>
          <div class="py-25">
            <span class="font-small-3 mr-50"> {{ $t('common.local_time') }} :</span>
            <b-badge v-if="steps" variant="light-secondary">{{ $moment.utc(steps.flightArrival.eventDateTime).utcOffset(steps.flightArrival.airport.timeZone).format('ddd, MMM Do YYYY HH:mm') }} </b-badge>
          </div>
        </div>
      </div>
    </b-card-text>
    <!-- <div class="col-12 px-0 py-50">
      <font-awesome-icon icon="users" style="width: 25px" />
      <span class="mx-50 font-weight-bolder">{{ trip.passengers }} {{
        $tc('trip.event.passenger_number', trip.passengers)
      }}</span>
    </div> -->
    <!-- <div class="col-12 px-0 py-50">
      <font-awesome-icon icon="plane" style="width: 21px" />
      <span class="mx-50 font-weight-bolder">{{ leg.slotNumber }} {{ $t('trip.event.slot.title') }}</span>
    </div> -->
  </section>
</template>

<script>
export default {
  name: 'CartTripDetails',

  data() {
    return {
      trip: null,
      steps: null,
      leg: null,
    }
  },
  mounted() {
    this.APIFetchTrip()
  },
  methods: {
    async APIFetchTrip() {
      const tripId = Number(this.$route.params.trip_id)
      try {
        this.trip = await this.$store.dispatch('trip/fetchTrip', { tripId, forced: false })
        this.trip.tripSteps.forEach(tripStep => {
          if (tripStep.flightDeparture && tripStep.flightArrival) {
            this.getCurrentTripStep(tripStep.flightDeparture)

            if (this.leg === null) {
              this.getCurrentTripStep(tripStep.flightArrival)
            }

            if (tripStep.id === Number(this.$route.params.step_id)) {
              this.steps = tripStep
            }
          }
        })
      } catch (error) {
        console.warn(error)
      }
    },
    getCurrentTripStep(tripStep) {
      let currentTripStep = null
      Object.entries(tripStep).forEach(([key, val]) => {
        if (key === 'id' && val === Number(this.$route.params.event_id)) {
          currentTripStep = tripStep
          this.leg = currentTripStep
        }
      })
    },
  },
}

</script>
